<template>
  <div>
    <v-menu
      offset-y
      rounded="xl"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          class="rounded-xl separador"
          v-bind="attrs"
          v-on="on"
        >
          <avatar-view
            size-avatar="36"
            store="usersStore"
            :file-name="userId"
            size-img="xs"
            rounded
          />
        </v-sheet>
      </template>
      <v-card flat class="rounded-xl" min-width="300">
        <v-row dense class="grey lighten-4 ma-0 pt-2">
          <v-col cols="12">
            <p class="subtitle-1 mb-0 font-weight-bold toguether-text">{{ userName }}</p>
            <p class="caption my-0 font-weight-light text--secondary">{{ userMail }}</p>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text class="text--primary pt-0">
          <v-row dense>
            <v-col cols="12">
              <v-list>
                <v-list-item
                  v-for="item in menu"
                  :key="item.id"
                  :to="item.link"
                >
                  <v-list-item-avatar class="text-start" size="18">
                    <v-icon small>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-start">{{ item.text }}</v-list-item-title>
                </v-list-item>
                <!-- <v-divider></v-divider>
                <v-list-item
                  @click="logOut"
                >
                  <v-list-item-avatar tile class="text-start" size="18">
                    <v-icon small>fa-arrow-right-from-bracket</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-start">Cerrar sesión</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
            block
            text
            tile
            class="rounded-b"
            @click="logOut()"
          >
            <v-icon small left>fa-arrow-right-from-bracket</v-icon>
            Cerrar sesión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { auth } from '@/plugins/firebase'
import { signOut } from 'firebase/auth'
import { mapActions, mapGetters } from 'vuex'
import AvatarView from './AvatarView.vue'

export default {
  name: 'UserMenu',
  components: {
    AvatarView
  },
  computed: {
    ...mapGetters(['logeado', 'userName', 'userMail', 'userId']),
  },
  data: () => ({
    loading: false,
    menu: [
      // {
      //   id: 0,
      //   text: 'Mis donaciones',
      //   icon: 'fa-hand-holding-hand',
      //   link: '/review'
      // },
      {
        id: 1,
        text: 'Mis deseos',
        icon: 'fa-hand',
        link: '/ineed'
      },
      {
        id: 2,
        text: 'Me gusta',
        icon: 'fa-heart',
        link: '/liked'
      },
      {
        id: 3,
        text: 'Perfil',
        icon: 'fa-user',
        link: '/profile'
      }
    ]
  }),
  methods: {
    ...mapActions(['logout']),
    logOut () {
      signOut(auth).then(() => {
        this.logout()
				this.$router.replace('/')
      }).catch(() => {
				this.logout()
				this.$router.replace('/login')
      })
    },
  }
}
</script>

<style>
.separador {
  border: 1px solid rgb(184, 178, 178) !important;
  padding: 1px;
}
</style>