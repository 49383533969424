import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { firebase } from './plugins/firebase'
import createStore from './store'
import VueMeta from 'vue-meta'
import algoliasearch from 'algoliasearch/lite' 

import './plugins/filters'
import '@fortawesome/fontawesome-free/css/all.css'
import './vee-validate'
import './plugins/vuetify-mask'
import './plugins/confetti'
import './moment'

Vue.use(VueMeta, {
  // optional pluginOptions
  // keyName: 'metaInfo',
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  // refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

createStore().then(store => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  
})

export default { firebase, algoliasearch }