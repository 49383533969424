<template>
<div class="pb-1 linebottom">
  <v-app-bar
    flat
    color="white"
    :height="$vuetify.breakpoint.lgAndUp ? '80' : '140'"
    :max-height="$vuetify.breakpoint.lgAndUp ? '80' : '140'"
    style="z-index: 9"
  >
    <v-row class="justify-space-around aling-center">
      <v-col cols="6" sm="4" md="3" lg="2">
        <router-link class="d-flex align-center" to="/">
          <v-avatar tile width="120">
            <v-img
              eager
              referrerpolicy="no-referrer"
              :src="logo"
              class="image-item"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0" align="center" justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    size="50"
                    width="3"
                    color="grey"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </router-link>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="11" md="7" lg="4">
        <searh-box></searh-box>
      </v-col>
      <v-col
        v-if="logeado"
        cols="6"
        md="3"
        class="d-flex justify-end align-center"
      >
        <notification-btn></notification-btn>
        <user-menu></user-menu>
      </v-col>
      <v-col
        v-else
        cols="6"
        md="3"
        class="d-flex justify-end align-center"
      >
        <v-btn
          v-if="!logeado && !isLogin"
          depressed
          rounded
          color="primary"
          to="/login"
        >
          Login
          <v-icon right small>
            fa-arrow-right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          depressed
          rounded
          color="primary"
          to="/"
        >
          <v-icon left small>
            fa-arrow-left
          </v-icon>
          Home
        </v-btn>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" sm="11" md="10">
        <searh-box></searh-box>
      </v-col>
    </v-row>
  </v-app-bar>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificationBtn from '../notifications/NotificationBtn.vue'
import SearhBox from './SearhBox.vue'
import UserMenu from './comunes/UserMenu.vue'

export default {
  name: 'AppBar',
  components: {
    NotificationBtn,
    SearhBox,
    UserMenu
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters(['logeado','logo']),
    isLogin () {
      return this.$route.name === 'login'
    }
  }
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
}
.link:hover {
  text-decoration:underline;
}
.linebottom {
  // border-bottom: 10px solid rgba($color: #00aaaa, $alpha: 1) !important;
  border-bottom: 1px solid rgba($color: #000000, $alpha: .05) !important;
}
.image {
  border: 1px solid rgba(0, 0, 0, 0.10);
}
.image-item{
  object-fit: cover;
  object-position: center;
  height: 220px;
  width: 220px;
}
</style>
