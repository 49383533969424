import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)

// Initialize Auth
const auth = getAuth()
auth.languageCode = 'es';
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebase);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebase);

//Initialize analytics
const analytics = getAnalytics(firebase);

export { firebase, auth, db, storage, analytics }