import { storage } from '@/plugins/firebase'
import { ref, getDownloadURL } from "firebase/storage"
import { fileSizes } from '@/persistencia/fileSizes'

async function getImageStore (store, photoName, photoExt, sizeCode = null) {
  try {
    if (photoName && photoExt) {
      let refImg = null
      const size = getSize(sizeCode)
      const photoURL = photoName + '_'+ size + photoExt
      refImg = ref(storage, store +'/' + photoURL)
      const result =  await getDownloadURL(refImg)
      return result
    } else {
      return '/no_image.png'
    }
  } catch {
    return '/no_image.png'
  }
}
async function getImagePublish (path, sizeCode = null) {
  try {
    if (path) {
      let refImg = null
      const size = getSize(sizeCode)
      const photoURL = path + '_'+ size + '.webp'
      refImg = ref(storage, photoURL)
      const result =  await getDownloadURL(refImg)
      return result
    } else {
      return '/no_image.png'
    }
  } catch {
    return '/no_image.png'
  }
}

function getSize (sizeCode = null) {
  if (sizeCode) {
    const indexSize  = fileSizes.findIndex(sizes => sizes.code === sizeCode)
    let size = '800x600'
    if (indexSize >= 0 ) {
      size = fileSizes[indexSize].size
    }
    return size
  } else {
    return '800x600'
  }
}

export default { getImageStore, getSize, getImagePublish }
