import Vue from 'vue'
import VueRouter from 'vue-router'
import createStore from '@/store'
import accessControl from '../middleware/accessControl';
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/polices',
    name: 'polices',
    component: () => import('../views/PolicesView.vue')
  },
  {
    path: '/colaborate',
    name: 'colaborate',
    component: () => import('../views/ColaborateView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/RegisterView.vue')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/AppView.vue'),
    // meta: {
    //   autenticado: true,
    // middleware: accessControl
    // }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/ItemDetailView.vue'),
    // meta: {
    //   autenticado: true,
    // middleware: accessControl
    // }
  },
  {
    path: '/liked',
    name: 'liked',
    component: () => import('../views/app/LikesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ineed',
    name: 'ineed',
    component: () => import('../views/app/CandidatizeView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../views/app/CreateDonation.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('../views/app/ReviewView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/app/NotificationsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/app/UserProfile.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/app/ProfileView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

router.beforeEach(async (to, from, next) => {
  let usuario = null
  await createStore().then(store => {
    usuario = store.getters.logeado
  })
  // console.log('to - from', to, from)
  let autorizacion = to.matched.some(record => record.meta.autenticado)
  // console.log('autorizacion - usuario', autorizacion, usuario)
  if (autorizacion && !usuario) {
    next("/login");
  } else if (!autorizacion && usuario) {
    next();
  } else if (autorizacion && usuario) {
    if (to.meta.middleware) {
      const middleware = to.meta.middleware;
      middleware({ next, to });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
