<template>
  <v-avatar
    :size="sizeAvatar"
    class=""
    :class="!rounded ? 'rounded':''"
    >
    <v-img
      eager
      referrerpolicy="no-referrer"
      v-bind:src="photoURL"
      lazy-src="/no_image.png"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0" align="center" justify="center"
        >
          <v-progress-circular
            indeterminate
            size="30"
            width="2"
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-avatar>
</template>
<script>
import imageFunction from '@/functions/imageFunction'
import { mapGetters } from 'vuex'
export default {
  name: 'AvatarView',
  props: {
    sizeAvatar: {
      type: String,
      require: false,
      default: '48'
    },
    fileName: {
      type: String,
      require: false,
      default: null
    },
    fileExt: {
      type: String,
      require: false,
      default: '.webp'
    },
    sizeImg: {
      type: String,
      require: false,
      default: 'xs'
    },
    rounded: {
      type: Boolean,
      require: false,
      default: false
    },
    store: {
      type: String,
      require: true
    },
    photo: {
      type: String,
      require: false,
      default: null
    }
  },
  data: () => ({
    photoURL: null
  }),
  mounted () {
    this.getImage(this.photo)
  },
  computed: {
    ...mapGetters(['userPhoto'])
  },
  methods: {
    async getImage (photo = null) {
      if (photo && photo.startsWith('http')) {
        this.photoURL = this.photo
      } else if (this.userPhoto && this.userPhoto.startsWith('http')) {
        this.photoURL = this.userPhoto
      } else {
        this.photoURL = await imageFunction.getImageStore(this.store, this.fileName, this.fileExt, this.sizeImg)
      }
		}
  }
}
</script>
<style>
</style>
