import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';
import '@/sass/overrides.sass'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#03c9d7',
        secondary: '#fb9678',
        accent: '#82B1FF',
        error: '#e46a76',
        info: '#0bb2fb',
        success: '#00c292',
        warning: '#fec90f',
        backcolor: '#121F3D',
        blackcolor: '#03091E',
        donategreen: '#03c9d7',
        donateback: '#121F3D'
      }
      // light: {
      //   primary: '#007BFF',
      //   secondary: '#424242',
      //   accent: '#82B1FF',
      //   error: '#FF5252',
      //   info: '#2196F3',
      //   success: '#4CAF50',
      //   warning: '#FFC107',
      //   donategreen: '#00FFAB',
      //   donateback: '#2c3e50'
      // },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
});
